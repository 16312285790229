import React from "react";
import PartnerContactForm from "./partners-contact-form";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const StyledContactForm = styled.div`
  display: grid;
  grid-gap: 1.5rem 3rem;
  max-width: 100rem;
  width: 80%;
  margin: 5rem auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  input,
  select,
  textarea {
    background-color: #fafafa;
    border: 1px solid rgba(#505050, 0.2);
    padding: 0.7rem 1.5rem;
    border-radius: 0.7rem;
  }
  input[name="subject"],
  textarea {
    grid-column: 1/3;
  }

  #title {
    grid-row: 1;
    padding: 20px 0 0 10px;
  }

  textarea {
    min-height: 12rem;
  }

  button {
    grid-column: 2/3;
    width: fit-content;
    margin-left: auto;
    padding: 0.7rem 3.5rem;
  }

  span {
    margin-top: 0.5rem;
  }
  i {
    background-color: white;
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    input[name="subject"],
    textarea,
    button {
      grid-column: 1/2;
    }
  }
`;

const PartnersContactFormWithTitle = ({ contactString }) => {
  const {
    sanityForms: { contactForm: formInfo },
  } = useStaticQuery(graphql`
    query PartnersContactFormInfoQuery {
      sanityForms {
        contactForm {
          Title
        }
      }
    }
  `);

  return (
    <StyledContactForm>
      <div className="title" id="title">
        {/* <h2>{formInfo.Title}</h2> */}
        <h4 className="titleh4">{contactString || formInfo.Title}</h4>
      </div>

      <PartnerContactForm />
    </StyledContactForm>
  );
};

export default PartnersContactFormWithTitle;
