import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Spin } from "antd";
import axios from "axios";
import BlocksContent from "../../../blocksContent";
import { useStore } from "@state/store";
import styled from "styled-components";

const StyledContactForm = styled.div`
  grid-row: 2;
  max-width: 1200px;
  width: 100%;
  padding: 0 40px 0 10px;
  .titleh4 {
    font-size: 21px;
    line-height: 4rem;
  }

  #contact_form {
    display: grid;
    grid-gap: 1.5rem 3rem;
    margin: 10px auto 40px auto;
  }

  @media screen and (max-width: 650px) {
    box-shadow: unset;
    padding: unset;
    margin-top: 0px;
    .container {
      width: 100% !important;
      max-width: unset;
      margin: unset;
    }
    .title {
      margin-bottom: 2rem;
    }
  }
`;

const PartnersContactForm = () => {
  const {
    sanityForms: { contactForm: formData },
  } = useStaticQuery(graphql`
    query PartnersContactFormQuery {
      sanityForms {
        contactForm {
          errorMessage {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          successMessage {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `);
  const initialData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    position: "",
    message: "",
    ip_located_country: "",
  };
  const [data, setData] = useState(initialData);
  const [submited, setSubmited] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitFaled, setSubmitFaled] = useState(false);
  const [formDisable, setFormDisable] = useState(true);

  const { ipLookup, dataLayer } = useStore();

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setData({ ...data, ip_located_country: ipLookup.countryName });
  }, [ipLookup]);

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post("https://getform.io/f/59829da6-385e-4973-9b24-9b57e2195bbe", data, {
        headers: { Accept: "application/json" },
      })
      .then(_ => {
        setSubmited(true);
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
          event: dataLayer.event,
        });
      })
      .catch(_ => {
        setSubmited(true);
        setSubmitFaled(true);
      });
  };
  const verifyCallback = recaptchaToken => {
    if (recaptchaToken) setFormDisable(false);
  };
  if (submited)
    return (
      <div
        className={`container submition-feedback ${
          submitFaled ? "error" : "success"
        }`}
      >
        {submitFaled ? (
          <BlocksContent blocks={formData.errorMessage._rawColumnContent} />
        ) : (
          <BlocksContent blocks={formData.successMessage._rawColumnContent} />
        )}
      </div>
    );
  return (
    <StyledContactForm className="container">
      <form onSubmit={handleSubmit} id="contact_form">
        <input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={data.first_name}
          onChange={handleChange}
          aria-required="true"
          required
        />
        <input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={data.last_name}
          onChange={handleChange}
          aria-required="false"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={data.email}
          onChange={handleChange}
          aria-required="true"
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone (Optional)"
          value={data.phone}
          onChange={handleChange}
          aria-required="false"
        />
        <input
          type="text"
          name="company"
          placeholder="Company"
          value={data.company}
          onChange={handleChange}
          aria-required="false"
        />
        <input
          type="text"
          name="position"
          placeholder="Position"
          value={data.position}
          onChange={handleChange}
          aria-required="false"
        />
        <textarea
          name="message"
          placeholder="Message"
          value={data.message}
          onChange={handleChange}
          aria-required="false"
        ></textarea>
        <button type="submit" className="theme-blue-btn" disabled={formDisable}>
          {submitting ? <Spin /> : "Submit"}
        </button>
      </form>
    </StyledContactForm>
  );
};
export default PartnersContactForm;
